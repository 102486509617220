import React from "react";
import { ButtonColour, ButtonSize } from "../../../components/layout/Button";
import { GamePageData } from "./RecipeRescuePageData";

export const recipeRescueFruitBlastPageData: GamePageData = {
    metaPageTitle: "Recipe Rescue: Fruit Blast Match-3 Puzzle Game - iDream Interactive",
    metaKeywords: "recipe rescue, recipe rescue fruit blast, recipe rescue game, how to play recipe rescue",
    metaDescription: "Complete tasks and help Nina restore and rescue restaurants while playing the juiciest match 3 out there!",
    pageTitle: "Recipe Rescue: Fruit Blast",
    descriptionTitle: "Grab your apron and cook your way through challenging restaurants, and raise to the top!",
    description: <>
        <p>Mix and match ingredients for mouth-watering combinations, and create delectable dishes that leave your customers drooling for more!</p>
        <p>Help upgrade objects and turn run down restaurants into new and improved ones!</p>
        <p>Complete tasks and help Nina restore and rescue restaurants while playing the juiciest match 3 out there!</p>
    </>,
    links: [
        {
            ctaLabel: "App Store",
            ctaLink: "https://apps.apple.com/us/app/recipe-rescue-fruit-blast/id1473143646",
            externalLink: true,
            colour: ButtonColour.BLUE,
            size: ButtonSize.LARGE,
            id: "appStoreGradient",
            ariaLabel: "Play Recipe Rescue: Fruit Blast on iOS"
        },
        {
            ctaLabel: "Google Play",
            ctaLink: "https://play.google.com/store/apps/details?id=com.idreaminteractive.rrfb&hl=en_CA&gl=US",
            externalLink: true,
            colour: ButtonColour.BLUE,
            size: ButtonSize.LARGE,
            id: "googlePlayGradient",
            ariaLabel: "Play Recipe Rescue: Fruit Blast on Android"
        },
    ]
}
